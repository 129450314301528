<template>
  <div class="contact-us">
    <h1 class="comm-title">{{$t('pageHome.contactus.title')}}</h1>
    <div class="contact-us-content">
      <div class="wx-code">
        <img class="code" src="./images/wx-code.png">
        <div class="text">{{$t('pageHome.contactus.wx')}}</div>
      </div>
      <div class="map-box" :class="{en:lang==='en'}"></div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "ContactUs",
  computed:{
    ...mapState(['lang'])
  }
}
</script>

<style lang="scss" scoped>
.contact-us{
  padding-top: 24px;
  .contact-us-content{
    display: flex;
    justify-content: center;
    .wx-code{
      width: 307px;
      margin-right: 52px;
      .code{
        width: 307px;
        height: 307px;
      }
      .text{
        display: block;
        text-align: center;
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        color: #C8171E;
      }
    }
    .map-box{
      width: 721px;
      height: 312px;
      background: url("./images/map.png") no-repeat;
      background-size: 100% 100%;
      &.en{
        background: url("./images/map-en.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
