<template>
<div class="banner-box">
  <div class="banner-slider-box">
    <el-carousel :key="globalScale" :height="sliderHeight" trigger="click" indicator-position="outside">
      <el-carousel-item v-for="(item,index) in 3" :key="item">
        <div :class="[`banner-${index+1}`]"></div>
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="container banner-text-box">
    <div class="text-wrap">
       <p>{{$t('pageHome.bannerText')}}</p>
<!--      <p>风物资本是聚焦中国视角，专注跨境创新的早期风险基金。 </p>-->
<!--      <p>关注全球范围内的科技应用与新兴消费的投资机会，于新加坡、上海、东京分别设有办公室。</p>-->
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BannerSlider",
  data(){
    return {
    }
  },
  computed:{
    ...mapState(['globalScale']),
    sliderHeight(){
      return parseInt(this.globalScale * 600) + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-box{
   background: #EFEFEF;
   ::v-deep{
     .el-carousel__indicators--outside button{
       width: 8px;
       height: 8px;
       background: #000000;
       border-radius: 4px;
       opacity: 0.2
     }
     .el-carousel__indicator--horizontal{
       &.is-active{
         button{
           width: 20px;
           height: 8px;
           background: #C7171E;
           border-radius: 4px;
           opacity: 1;
         }
       }
     }
   }
  .banner-slider-box{
    height:600px;
    .banner-1{
      height: 100%;
      background: url('./images/banner-02.png') no-repeat center;
      background-size: auto 100%;
    }
    .banner-2{
      height: 100%;
      background: url('./images/banner-03.png') no-repeat center;
      background-size: auto 100%;
    }
    .banner-3{
      height: 100%;
      background: url('./images/banner-01.png') no-repeat center;
      background-size: auto 100%;
    }
  }
  .banner-text-box{
    padding-top: 56px;
    padding-bottom: 36px;
    box-sizing: border-box;
    height: 220px;

    .text-wrap{
      height: 128px;
      text-align: center;
      >p{
        padding-top: 40px;
        font-size: 42px;
        color: #231916;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
