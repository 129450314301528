<template>
<div>
  <banner-slider />
  <home-case />
  <comm-news />
  <contact-us />
</div>
</template>

<script>
import CommNews from "@/components/CommNews";
import ContactUs from "@/components/ContactUs";
import BannerSlider from "@/components/BannerSlider";
import HomeCase from "@/components/HomeCase";
export default {
  name: "Home",
  components: {HomeCase, BannerSlider, ContactUs, CommNews}
}
</script>

<style lang="scss" scoped>
</style>
