<template>
  <div class="news-box">
    <div class="container">
      <h4 class="comm-title">{{$t('pageHome.news.title')}}</h4>
      <el-row :gutter="40">
        <el-col :span="8" v-for="(item,index) in showList" :key="index">
          <a class="new-item" :href="item.url" target="_blank">
            <div class="img-cover">
              <el-image
                  style="width: 100%;height: 100%;"
                  :src="item.thumb_url"
                  fit="cover" />
            </div>
            <div class="text-wrap">
              <div class="title"><span>{{item.title}}</span></div>
              <div class="desc">{{item.digest}}</div>
              <div class="tool">
                <span class="time">{{item.time}}</span>
                <span class="more">
                  <i class="icon-more" />
                </span>
              </div>
            </div>
          </a>
        </el-col>
      </el-row>
      <div class="news-bottom">
        <a href="javascript:;" class="more-btn">{{$t('pageHome.news.more')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {getPublishList} from "@/api";
import {timestampToDateFormat} from "@/utils"
export default {
  name: "CommNews",
  props: {
    list: {
      type: Array,
      default: () => {
        return [
          {
            title: "【风物Portfolio】| 伴你走入旖旎春光",
            thumb_url: require("@/assets/images/pic-640-1.webp"), //"pic-01.png",
            digest: " “好雨知时节”万物复苏，春意盎然风物portfolios伴你走入旖旎春光",
            time:" 2024-04-12",
            url:"https://mp.weixin.qq.com/s/7Pwus1tuKeA9rGehTuqZNA",
          },
          {
            title: "喜报！白盒子荣获高质量发展奖",
            thumb_url:  require("@/assets/images/pic-640-2.webp"), //"pic-02.png",
            digest: "3月27日，上海市松江区九亭镇召开2023年度经济高质量发展工作会议暨优秀企业表彰大会，松江区发改委、经委、科委等委办、临港松江科技城相关领导及九亭镇党委、人大、政府班子成员出席了会议。" +
                "白盒子（上海）微电子科技有限公司（以下简称“白盒子”）荣获2023年度九亭镇企业高质量发展奖，并在大会上进行了新质生产力主旨演讲。",
            time:"2024-04-08",
            url:"https://mp.weixin.qq.com/s/hVxjOxNMDWNJPvdZqdXFDg",
          },
          {
            title: "【风物Portfolio】| OC6020芯片完成eCPRI对接测试",
            thumb_url: require("@/assets/images/pic-640-3.webp"), //"pic-03.png",
            url:"https://mp.weixin.qq.com/s/q2fALEyCGBp6o50zYn3ZIA",
            digest: "白盒子（上海）微电子科技有限公司（以下简称“白盒子”）的新一代DFE芯片OC6020在eCPRI（enhanced Common Public Radio Interface）前传技术环境下，已顺利完成与主流仪表厂商（VIAVI和KEYSIGHT）的DU模拟器以及某设备厂商DU设备的对接测试。",
            time:" 2024-03-29",
          }
        ]
      }
    }
  },
  data(){
    return {
      news:[],
    }
  },
  computed:{
    showList(){
      return [
          ...this.news,
          ...this.list
      ].filter((item,index)=>index<3)
    }
  },
  created() {
    this.getNews()
  },
  methods:{
    async getNews(){
      const news = await getPublishList()
      if(news.item){
        this.news = news.item.map((item,index)=>{
          return {
            ...item.content.news_item[0],
            thumb_url:item.content.news_item[0].thumb_url.replace('https://mmbiz.qpic.cn/','/'),
            time:timestampToDateFormat(item.content.create_time*1000)
          }
        })
      }
      console.log(news)
    }
  }
}
</script>

<style lang="scss" scoped>
.news-box{
  background: #EFEFEF;
  padding-top:50px;
  padding-bottom: 54px;
  .news-title{
    font-weight: 600;
    font-size: 32px;
    color: #231916;
    line-height: 32px;
    text-align: center;
    font-style: normal;
  }
  .new-item{
    display: block;
    height: 480px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.04);
    margin-bottom: 21px;
    text-decoration: none;
    .img-cover{
      height: 320px;
      margin-bottom: 10px;
      >img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .text-wrap{
      padding-left: 24px;
      padding-right: 24px;
      .title{
        font-size: 16px;
        color: #231916;
        line-height: 22px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desc{
        font-size: 14px;
        color: #666666;
        height: 60px;
        line-height: 30px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

      }
      .tool{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time{
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
        .more{
          .icon-more{
            display: block;
            width: 96px;
            height: 9px;
            background: url('./images/icon-more.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .news-bottom{
    display: flex;
    justify-content: flex-end;
    .more-btn{
      display: block;
      min-width: 200px;
      padding-left: 7px;
      padding-right: 7px;
      height: 40px;
      background: #C7171E;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>
